.selo__txt__col{
	padding: 40px 15px;

	.sub__secao{
		color: #000;
		text-align: center;
		margin-bottom: 15px;
	}
}
.selo__tab{
	display: flex;
	justify-content: center;
	flex-wrap: nowrap !important;
	border-radius: 30px;
	padding: 5px;
	background-color: #fff;
	border: none;
	margin-right: auto;
	margin-left: auto;
	box-shadow: 0px 0px 10px rgba(#000, 0.5);
	margin-bottom: 20px;
	width: 100%;

	.nav-item{
		margin-bottom: 0px;

		.nav-link{
			border-radius: 30px;
			border: none;
			transition: all 0.3s linear;
			padding: 16px 16px 16px 16px;
			margin: 0px 5px;
			color: #000;
			font-family: 'latobold';
			font-size: 15px;
			line-height: 15px;

			&.active{
				background-color: #2275a8;
				color: #fff;
			}
		}
	}

}
.selo__visualizacao{
	display: flex;
	align-items: center;
	width: 33%;
	padding-right: 10px;
	min-width: 170px;
	line-height: 15px;
}
.titulo__selo{
	width: 67%;
	padding-right: 20px;
	line-height: 19px;
	text-align: left;
}
.segura__tab{
	text-align: center;
}
.olho{
	background-color: currentColor;
	margin-right: 5px;
}
.numero__visualizacao{
	margin-right: 5px;
	margin-top: 2px;
}
.txt__visualizaram{
	margin-top: 0px;
}
.selo__item__btn{
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	padding: 20px;
	border: none;
	font-family: 'latosemibold';
	color: #000;
	transition: all 0.3s linear;
	font-size: 15px;
	position: relative;
	align-items: center;
	text-align: left;

	@include media-breakpoint-down (sm){
		.txt__visualizaram{
			display: none;
		}
		.selo__visualizacao{
			min-width: 70px;
			width: 70px;
		}
		&:before{
			display: none;
		}
	}

	&:after{
		content: '';
		display: inline-block;
		position: relative;
		border-bottom: 14px solid #000;
		border-right: 8px solid transparent;
		border-left: 8px solid transparent;
		transition: all 0.4s linear;
		margin-top: 1px;
	}

	&:before{
		content: '';
		position: absolute;
		height: 20px;
		width: 1px;
		background-color: #000;
		top: 50%;
		right: 56px;
		transform: translateY(-50%);
		transition: all 0.3s linear;
	}

	&:focus{
		outline: none;
	}

	&[aria-expanded="true"]{
		color: #2275a8;

		.titulo__selo{
			text-decoration: underline;
		}

		&:after{
			transform: rotate(-180deg);
		}
		&:before{
			background-color: #2275a8;
		}
	}
}
.selo__card{
	border-bottom: 2px solid #ededed;
}
.selo__item__txt{
	padding: 30px 20px;
	text-align: justify;
	background-color: #fff;
}
.selo__interna{
	overflow: hidden;
}
.col__img__interna{
	position: relative;
}
.selo__row{
	align-items: center;
}
.selo__lista{
	box-shadow: 0px 0px 5px rgba(#000, 0.5);
	max-height: 600px;
	overflow-y: auto;

	&::-webkit-scrollbar{
		width: 5px;
	}
	&::-webkit-scrollbar-track{
		background-color: #fff;
	}
	&::-webkit-scrollbar-thumb{
		background-color: #2275a8;
		border-radius: 5px;
	}

}
@include media-breakpoint-down (lg){
	.col__img__interna{
		position: relative;
		height: 800px;
		order: 2;

		&:after{
			position: absolute;
			background-image: var(--background);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			content: '';
			right: 50%;
			transform: translateX(50%);
			top: 0;
			height: 100%;
			width: 100vw;
		}
	}
	.selo__tab{
		flex-direction: column;

		.nav-item{
			margin-bottom: 10px;

			&:last-child{
				margin-bottom: 0px;
			}
		}
	}
}
@include media-breakpoint-up (xl){
	.container__selo{
		width: 100%;
		max-width: 1920px;
		margin-right: auto;
		margin-left: auto;
		overflow: hidden;
	}
	.segura__selo__conteudo{
		max-width: 700px;
		width: 100%;
		margin-right: auto;
		margin-left: 0px;
	}
	.col__img__interna{
		padding-left: 0px;
	}
	.tab__content{
		max-height: 800px;
		overflow-y: auto;
	}
}
