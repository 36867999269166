.contato__interna{
	overflow: hidden;

	.sub__secao{
		color: #000;
		margin-bottom: 30px;
	}

	.col__img__interna{
		z-index: 1;

		// &:after{
		// 	z-index: -1;
		// 	position: absolute;
		// 	background-image: var(--background);
		// 	background-size: cover;
		// 	background-repeat: no-repeat;
		// 	background-position: left center;
		// 	content: '';
		// 	left: -160px;
		// 	top: 0;
		// 	height: 100%;
		// 	width: calc(1920px / 2);
		// }

		.fake-img{
			display:inline-block;
			background-size: cover;
			background-repeat: no-repeat;
			height: 100%;
			width:100%;
			background-position: center center;

			@include media-breakpoint-up(lg){
				background-position: left center;
				z-index: -1;
				position: absolute;
				left: -160px;
				top: 0;
				width: calc(1920px / 2);
			}

			// @include media-breakpoint-down(md){
			// 	@include img-fluid();
			// }

		}
	}
	.input__custom{
		color: #888888;
		border-bottom: 2px solid #ededed;

		&::placeholder{
			color: #888888;
		}

		&:focus{
			border-bottom: 2px solid #ededed;
		}
	}
}
.contato__interna__txt{
	padding: 30px 15px 70px 15px;
	z-index: 2;

	.produtos__card{
		background-color: #fff !important;
		box-shadow: 0px 0px 10px rgba(#000, 0.5);
		padding: 20px;
		border-radius:1px;
		-webkit-appearance:none;
		appearance:none;
		max-width: 350px;
		margin-bottom: 20px;
		min-height:209px;

		@include media-breakpoint-down(md){
			margin-bottom:40px;
			box-shadow: 0px 0px 5px rgba(#000, 0.5)
		}

	}
}
.modal__contato{
	.modal-dialog{
		max-width: 1100px;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
	}
	.modal-header{
		padding: 20px;
		display: flex;
		justify-content: flex-end;
		border: none;

		button{
			font-family: 'latobold';
			font-size: 15px;
			line-height: 15px;
			color: #2275a8;
			background-color: transparent;
			border: none;

			&:focus{
				outline: none;
			}
		}
	}
	.modal-body{
		max-width: 940px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
	}
}
.filecustom{
	position: relative;

	&.focus{

		.filecustom-placeholder{
			opacity: 0;
		}
	}

	label{
		margin-bottom: 0;
	}

	.filecustom-output{
		border: none;
		background-color: #fff;
		color: #888888;
		font-family: 'latobold';
		font-size: 18px;
		line-height: 22px;
		padding: 10px 40px 10px 10px;
		height: 38px;
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		text-align: left;
		border-bottom: 2px solid #ededed;

		&:before{
			content: '\f0c6';
			width: 34px;
			font-weight: 900;
			height: 34px;
			line-height: 1em;
			color: #ededed;
			font-size: 26px;
			font-family: 'Font Awesome 5 Free';
			position: absolute;
			right: 0;
			top: 0;
			z-index: 4;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
	.filecustom-placeholder{
		position: absolute;
		left: 10px;
		top: 5px;
		line-height: 1.2em;
		font-size: 18px;
		line-height: 22px;
		color: #888888;
		z-index: 5;
		cursor: pointer;
		font-family: 'latobold';
	}
	.filecustom-input{
		position: fixed;
		top: -200px;
	}
}
.box__confirmar{
	border: 2px solid #ededed;
	padding: 40px;
}
.modal__desc{
	margin-bottom: 40px;
}
.titulo__confirmar{
	color: #000000;
	font-size: 16px;
	margin-bottom: 30px;
}
.desc__confirmar{
	margin-bottom: 30px;
}
.form__checkbox{
	.check__custom{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .label__check__custom{
				&:before{
					color: rgba(#0e3959, 1);
				}
			}
		}
	}

	.label__check__custom{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:before{
			width: 20px;
			height: 20px;
			border: 3px solid #0e3959;
			content: '\f00c';
			position: relative;
			margin-right: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			color: rgba(#0e3959, 0);
			transition: all 0.3s linear;
		}

		&:hover{
			&:before{
				background-color: rgba(#000, 0.2);
			}
		}
	}
}
@include media-breakpoint-down (md){
	.contato__interna{
		overflow: hidden;

		.col__img__interna{
			position: relative;
			height: 800px;
			order: 2;

			&:after{
				position: absolute;
				background-image: var(--background);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				content: '';
				left: 50%;
				transform: translateX(-50%);
				top: 0;
				height: 100%;
				width: 100vw;
			}
		}
	}
}
