.privacidade__interna{
	padding: 30px 0px 80px 0px;
}
.privacidade__header{
	text-align: center;
	color: #000;
	margin-bottom: 20px;
}
.ctrls__depoimentos{
	color: #2275a8;
	font-size: 24px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	padding-top: 30px;
}
.politica__img{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 100px 0px;
}
.politica__titulo{
	font-size: 60px;
	color: #fff;
	text-align: center;
	max-width: 800px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	line-height: 64px;
	font-family: 'latosemibold';
	position: relative;

	@include media-breakpoint-down (md) {
		font-size: 46px;
		line-height: 50px;
	}

	@include media-breakpoint-down (xs) {
		font-size: 38px;
		line-height: 42px;
	}

	&:after{
		width: 80px;
		height: 4px;
		background-color: #fff;
		position: absolute;
		top: calc(100% + 20px);
		left: 50%;
		transform: translateX(-50%);
		content: '';
	}
}
