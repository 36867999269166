.modal__entrada{
	.modal-dialog{
		max-width: 700px;
		padding: 0px;
		border-radius: 0px;
		width: 100%;

		.modal-content{
			padding: 0px;
			background-color: transparent;
			border: none;
			border-radius: 0px;

			.modal-header{
				padding: 0px;
				border: none;
				display: flex;
				justify-content: flex-end;
				margin-bottom: 20px;

				button{
					font-family: 'latobold';
					font-size: 15px;
					line-height: 15px;
					color: #fff;
					background-color: transparent;
					border: none;

					&:focus{
						outline: none;
					}
				}
			}

			.modal-body{
				padding: 0px;
			}
		}
	}
}
.banner__carousel{
	width: 100%;
	max-width: 1920px;
	overflow: hidden;
	margin-right: auto;
	margin-left: auto;
	position: relative;
}
.sociais__lista{
	position: absolute;
	top: 50%;
	left: 10%;
	transform: translateY(-50%);
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	z-index: 10;
}
.sociais__item{
	&:last-child{
		.sociais__link{
			margin-bottom: 5px;
		}
	}
}
.sociais__link{
	width: 20px;
	height: 20px;
	border-radius: 2px;
	background-color: #fdb813;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #2275a8;
		color: #fff;
		text-decoration: none;
	}
}
.banner__controls{
	position: absolute;
	bottom: 10%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
}
.banner__prev,
.banner__next{
	font-size: 23px;
	line-height: 23px;
	display: inline-flex;
	margin: 0px 10px;
	text-shadow: 0px 0px 5px rgba(#fff, 0);
	transition: all 0.3s linear;
	color: #fff;

	&:hover{
		text-decoration: none;
		color: darken(#fff, 10%);
	}
}
.segura__tudo{
	position: relative;

	&:after{
		z-index: -1;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100%;
		background-image: url('../images/bg__tudo.png');
		background-size: 1920px 2164px;
		background-repeat: no-repeat;
		background-position: center top;
		content: '';
	}
}
.argon{
	padding: 50px 0px;
	z-index: 1;
}
.sub__secao{
	font-size: 30px;
	line-height: 34px;
	font-family: 'latomedium';
}
.argon__header{
	margin-bottom: 25px;
	color: #000000;
	position: relative;
	z-index: 2;

	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 120px;
		z-index: -1;
		background-image: url('../images/argon__letra.png');
		background-size: 547px 113px;
		background-repeat: no-repeat;
		background-position: left top;
		left: 50%;
		transform: translateX(-50%);
		top: -80px;
	}

	.sub__secao{
		--linhas: 3;
	}
}
.argon__desc{
	color: #fff;
	--linhas: 7;
	margin-bottom: 50px;
}
.argon__row{
	align-items: center;
}
.btn__conteudo{
	font-family: 'latobold';
	font-size: 15px;
	color: #000;
	line-height: 15px;
	display: inline-flex;
	padding: 15px;
	position: relative;
	align-items: center;
	transition: all 0.3s linear;

	&:before{
		content: '';
		position: relative;
		display: inline-block;
		width: 0px;
		height: 3px;
		background-color: #000;
		margin-right: 10px;
		transition: all 0.3s linear;
	}
	&:after{
		content: '';
		position: relative;
		display: inline-block;
		width: 20px;
		height: 3px;
		background-color: #000;
		margin-left: 10px;
		transition: all 0.3s linear;
	}
	&:hover{
		color: #fdb813;
		text-decoration: none;

		&:before{
			background-color: #fdb813;
			width: 20px;
		}
		&:after{
			background-color: #fdb813;
			width: 0px;
		}
	}
}
.argon__btn{
	text-align: right;
}
.missao__btn{
	width: 100%;
	position: relative;
	font-size: 30px;
	line-height: 34px;
	font-family: 'latomedium';
	background-color: transparent;
	text-align: left;
	border: none;
	margin-bottom: 0px;
	padding: 5px;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		z-index: -1;
		width: 100%;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		height: 2px;
		background-color: #ededed;
	}

	&:focus{
		outline: none;
	}

	span{
		background-color: #fff;
		position: relative;
		display: inline-flex;
		align-items: center;
		padding-right: 20px;

		&:after{
			content: '[+]';
			position: relative;
			display: inline-block;
			margin-left: 5px;
		}
	}

	&[aria-expanded="true"]{
		span{
			&:after{
				content: '[-]';
			}
		}
	}
}
.missao__collapse{
	padding: 0px 10px;
}
.missao__card{
	margin-bottom: 10px;
}
.missao__conteudo{
	--linhas: 3;
}
.numeros{
	padding: 80px 0px 40px 0px;
}
.numeros__card{
	width: 100%;
	max-width: 255px;
	height: 255px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border-top: 20px solid #2275a8;
	border-bottom: 20px solid #2275a8;
	border-right: 20px solid #dcdcdc;
	border-left: 20px solid #dcdcdc;
	text-align: center;
}
.numero__numeros{
	color: #2275a8;
	font-family: 'latoblack';
	font-size: 48px;
	line-height: 52px;
	margin-bottom: 5px;
}
.texto__numeros{
	color: #000;
	font-family: 'latosemibold';
	font-size: 15px;
}
.row__numeros{
	position: relative;
	z-index: 1;

	&:after{
		width: 100%;
		height: 150px;
		left: 50%;
		transform: translateX(-50%);
		top: -70px;
		content: '';
		position: absolute;
		z-index: -1;
		background-image: url('../images/numeros__letra.png');
		background-repeat: no-repeat;
		background-size: 753px 143px;
		background-position: left top;
	}
}
.produtos{
	padding: 80px 0px 0px 0px;
}
.produtos__card__header{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
}
.produtos__titulo{
	font-family: 'latobold';
	font-size: 18px;
	line-height: 22px;
	margin-left: 30px;
	color: #010101;
	transition: all 0.3s linear;
}
.produtos__img{
	width: 66px;
	height: 66px;
	min-width: 66px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	box-shadow: 0px 0px 5px rgba(#000, 0.6);
	border-radius: 50%;
	transition: all 0.3s linear;
}
.produtos__card{
	display: block;
	padding: 10px;
	max-width: 270px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;

	&:hover{
		text-decoration: none;

		.produtos__img{
			background-color: #2275a8;
			box-shadow: 0px 0px 5px rgba(#000, 0);
		}
		.produtos__icone{
			background-color: #fff;
		}
		.produtos__titulo{
			color: #2275a8;
		}
		.btn__conteudo{
			color: #fdb813;

			&:after{
				background-color: #fdb813;
				width: 0px;
			}
			&:before{
				background-color: #fdb813;
				width: 20px;
			}
		}
	}
}
.produtos__icone{
	width: 40px;
	height: 40px;
	mask-size: auto;
	mask-position: center center;
	mask-repeat: no-repeat;
	-webkit-mask-size: auto;
	-webkit-mask-position: center center;
	-webkit-mask-repeat: no-repeat;
	background-color: #000;
	transition: all 0.3s linear;
}
.produtos__card__desc{
	--linhas: 6;
	text-align: justify;
}
.produtos__card__btn{
	text-align: right;
}
.produtos__header{
	margin-bottom: 30px;
	position: relative;
	z-index: 1;

	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 120px;
		top: -60px;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../images/produtos__letra.png');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 828px 113px;
		z-index: -1;
	}

	.sub__secao{
		color: #000;
		text-align: center;
	}
}
.produtos__desc{
	max-width: 900px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	margin-bottom: 40px;
	text-align: center;
}
.segura__controls{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
}
.produtos__controls{
	display: flex;
	align-items: center;
	font-size: 22px;
	line-height: 22px;
	color: #2275a8;

	.barrinha{
		font-size: 18px;
	}
}
.owl__prev,
.owl__next{
	&:hover{
		text-decoration: none;
	}
}
.servicos__e__produtos{
	position: relative;
	overflow: hidden;

	&:before{
		content: '';
		position: absolute;
		width: 1920px;
		left: 50%;
		transform: translateX(-50%);
		top: 0px;
		background-image: url('../images/servicos__detalhes.png');
		background-repeat: no-repeat;
		background-size: 1336px 952px;
		background-position: right bottom;
		height: 100%;
		z-index: -2;
	}
}
.servicos{
	padding: 150px 0px 80px 0px;
	position: relative;
	overflow: hidden;

	&:after{
		content: '';
		position: absolute;
		width: 1920px;
		left: 50%;
		transform: translateX(-50%);
		top: 0px;
		background-image: url('../images/servicos__bg.png');
		background-repeat: no-repeat;
		background-size: 1920px 648px;
		background-position: center top;
		height: 100%;
		z-index: -1;
	}
}
.servicos__conteudo{
	max-width: 760px;
	width: 100%;
	margin-left: auto;
	margin-right: 0px;
}
.servicos__header{
	color: #000000;
	position: relative;
	z-index: 1;
	margin-bottom: 45px;
	text-align: center;

	&:after{
		width: 100%;
		height: 150px;
		content: '';
		position: absolute;
		z-index: -1;
		left: 50%;
		transform: translateX(-50%);
		top: -70px;
		background-image: url('../images/servicos__letra.png');
		background-repeat: no-repeat;
		background-size: 718px 138px;
		background-position: center top;
	}
}
.servicos__txt{
	--linhas: 6;
}
.servicos__btn{
	text-align: right;
}
.parceiros{
	padding: 70px 0px 40px 0px;

	.container{
		border-bottom: 3px solid #ededed;
	}
}
.parceiros__header{
	margin-bottom: 70px;
	text-align: center;
	position: relative;
	color: #000;

	&:after{
		content: '';
		position: absolute;
		top: -60px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 120px;
		z-index: -1;
		background-image: url('../images/fornecedores__bg.png');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100% auto;
	}
}
.segura__tudo{
	position: relative;

	&:before{
		width: 100%;
		height: 100%;
		z-index: -2;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../images/bg__depoimentos.png');
		background-repeat: no-repeat;
		background-size: 1920px 697px;
		background-position: center bottom;
		content: '';
		position: absolute;
	}
}
.parceiros__card{
	display: block;
	max-width: 170px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	position: relative;

	&:hover{
		text-decoration: none;

		img{
			filter: grayscale(0%);
		}
		.parceiros__nome{
			color: #2275a8;
		}
	}

	img{
		filter: grayscale(100%);
		transition: all 0.3s linear;
	}

}
.parceiros__nome{
	font-family: 'latobold';
	font-size: 15px;
	line-height: 19px;
	color: #000000;
	transition: all 0.3s linear;
	text-align: center;
}
.owl__parceiros.owl-carousel{
	margin-bottom: 30px;
	position: relative;
	padding: 0px 10px;

	.owl-nav{
		.owl-prev,
		.owl-next{
			color: #2275a8;
			font-size: 22px;
			line-height: 22px;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;

			&:focus{
				outline: none;
			}
		}
		.owl-next{
			right: -10px;
		}
		.owl-prev{
			left: -10px;
		}
	}
}
.depoimentos{
	padding-bottom: 30px;
}
.depoimentos__header{
	color: #000;
	text-align: center;
	margin-bottom: 10px;
}
.depoimento__card{
	display: flex;
	padding: 20px 30px;
	align-items: center;
}
.depoimento__img{
	border-radius: 50%;
	overflow: hidden;
	min-width: 140px;
	margin-right: 15px;

	img{
		border-radius: 50%;
	}
}
.depoimento__conteudo{
	margin-bottom: 15px;
}
.depoimento__ident{
	display: flex;
	align-items: center;
	font-family: 'latobold';
}
.depoimento__baixo{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;
}
.depoimentos__controls{
	color: #2275a8;
	font-size: 22px;
	line-height: 22px;
	display: flex;
	align-items: center;

	.barrinha{
		font-size: 18px;
	}
}
.selo__digital{
	padding: 80px 0px 60px 0px;
	background-image: url('../images/bg__selo.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	text-align: center;
}
.selo__header{
	position: relative;
	z-index: 1;
	color: #000;
	padding-bottom: 40px;

	&:after{
		width: 100%;
		height: 120px;
		background-image: url('../images/selo__letra.png');
		background-size: 970px 114px;
		background-position: center top;
		background-repeat: no-repeat;
		content: '';
		position: absolute;
		z-index: -1;
		top: -50px;
		left: 50%;
		transform: translateX(-50%);
	}
}
.selo__desc{
	color: #000;
	max-width: 890px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 20px;
}
.selo__btn,
.blog__btn{
	.btn__conteudo{
		&:hover{
			color: #fff;

			&:before,
			&:after{
				background-color: #fff;
			}
		}
	}
}
.blog{
	padding: 80px 0px 50px 0px;
}
.blog__header{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 20px;
	position: relative;

	&:after{
		width: 100%;
		height: 120px;
		content: '';
		position: absolute;
		top: -55px;
		left: 0px;
		background-image: url('../images/blog__letra.png');
		background-position: left top;
		background-repeat: no-repeat;
		background-size: 396px 113px;
		z-index: -1;
	}

	.sub__secao{
		color: #000;
	}
}
.blog__desc{
	text-align: justify;
}
.blog__btn{
	text-align: right;
}
.blog__card{
	display: block;
	padding-bottom: 40px;
	position: relative;
	max-width: 350px;
	width: 100%;
}
.blog__info{
	width: 100%;
	max-width: 90%;
	margin-right: auto;
	margin-left: auto;
	padding: 25px 20px 10px 20px;
	background-color: #fff;
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0px 0px 5px rgba(#000, 0.6);
}
.blog__img{
	position: relative;

	&:after{
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(#000, 0.5);
		position: absolute;
		content: '';
		opacity: 0;
		transition: all 0.3s linear;
	}
}
.blog__card__desc{
	--linhas: 6;
	height: 0px;
	transition: all 0.3s linear;
}
.blog__card{
	&:hover{
		text-decoration: none;

		.blog__card__desc{
			height: 101px;
		}
		.blog__img{
			&:after{
				opacity: 1;
			}
		}
		.btn__conteudo{
			color: #fdb813;
			text-decoration: none;

			&:before{
				background-color: #fdb813;
				width: 20px;
			}
			&:after{
				background-color: #fdb813;
				width: 0px;
			}
		}
		.blog__titulo{
			color: #2275a8;
		}
	}
}
.blog__card__btn{
	text-align: right;
}
.blog__detalhe{
	display: flex;
	align-items: center;
	font-size: 14px;
}
.blog__titulo{
	--linhas: 2;
	font-family: 'latobold';
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
	transition: all 0.3s linear;
}
.blog__carousel.owl-carousel{

	.blog__card{
		max-width: 350px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			color: #2275a8;
			text-shadow: 0px 0px 5px rgba(#000, 0.5);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 28px;
			line-height: 28px;

			&:hover{
				color: #fdb813;
				text-shadow: 0px 0px 5px rgba(#000, 0.5);
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
.segura__blog__rodape,
.segura__rdp__interna{
	position: relative;

	&:after{
		z-index: -1;
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100%;
		background-image: url('../images/bg__blog.png');
		background-repeat: no-repeat;
		background-size: 1920px 997px;
		background-position: center bottom;
	}
}
@include media-breakpoint-up (lg){
	.banner__carousel{
		margin-top: -100px;
	}
	.argon__col__txt{
		padding-top: 50px;
	}
	.blog__header{
		.sub__secao{
			width: 100%;
			max-width: 300px;
			margin-right: 20px;
		}
	}
	.blog__texto{
		max-width: 730px;
		width: 100%;
	}
	.segura__servicos{
		max-width: 800px;
		width: 100%;
		margin-right: 0px;
		margin-left: auto;
	}
}
@include media-breakpoint-down (md){
	.segura__tudo{
		&:after{
			z-index: -1;
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(-50%);
			width: 100%;
			height: 100%;
			background-image: url('../images/bg__tudo.png');
			background-size: 1920px 2164px;
			background-repeat: no-repeat;
			background-position: right top;
			content: '';
		}
	}
	.argon__row{
		.col-lg-5{
			order: 2;
		}
		.col-lg-6{
			order: 1;
		}
	}
	.argon__header{
		margin-top: 80px;

		&:after{
			content: '';
			position: absolute;
			width: 100%;
			max-width: 100%;
			height: 120px;
			z-index: -1;
			background-image: url('../images/argon__letra.png');
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center top;
			left: 50%;
			transform: translateX(-50%);
			top: -80px;
		}
	}
	.argon__header{
		.sub__secao{
			--linhas: 4;
		}
	}
	.numeros__card{
		margin-bottom: 30px;
	}
	.argon__desc{
		--linhas: 10;
	}
	.produtos__header,
	.servicos__header,
	.parceiros__header{

		&:after{
			background-size: 100% auto;
			background-position: center top;
		}
	}
	.missao__btn{
		&:after{
			display: none;
		}
		span{
			background-color: transparent;
		}
	}
	.blog__card{
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
		padding-bottom: 100px;
	}
	.blog__card__desc{
		height: 101px;
	}
	.blog__header{
		flex-direction: column;

		.sub__secao{
			margin-bottom: 20px;
		}
	}
}
@include media-breakpoint-down (sm){
	.sub__secao{
		font-size: 24px;
		line-height: 28px;
	}
	.argon__header{
		.sub__secao{
			--linhas: 4;
		}
	}
	.argon__desc{
		--linhas: 12;
	}
	.numeros__card{
		 margin-bottom: 20px;
	}
	.row__numeros{
		&:after{
			width: 100%;
			height: 150px;
			left: 50%;
			transform: translateX(-50%);
			top: -70px;
			content: '';
			position: absolute;
			z-index: -1;
			background-image: url('../images/numeros__letra.png');
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: left top;
		}
	}
	.depoimento__card{
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	.depoimento__img{
		margin-bottom: 20px;
	}
	.depoimento__ident{
		justify-content: center;
	}
	.depoimento__baixo,
	.segura__controls{
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.depoimentos__controls,
	.produtos__controls{
		margin-bottom: 20px;
	}
	.missao__btn{
		font-size: 24px;
	}
}
@include media-breakpoint-down (xs){
	.missao__btn{
		font-size: 22px;
	}
}
