.page__certificacoes{
	padding-top: 50px;
	padding-bottom: 50px;

	@include media-breakpoint-down (sm){
		overflow-x: hidden !important;
	}



	.sub__secao{
		color: #000;
		margin-bottom: 50px;
	}

	.btn__conteudo{
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.certificado{
		margin-bottom: 40px;
		transition: transform 0.4s linear;
		margin-left: auto !important;
		margin-right: auto !important;
		display: flex;
		text-decoration: none;

		.btn__pdf {
			width: 180px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-family: 'latobold';
			background: #2275a8;
			transition:  background .4s linear;
			margin-top: 20px;

			&:hover{
				background: #fdb813;
			}
		}

		@include media-breakpoint-down (md){
			flex-direction: column;
			align-items: center;
		}

		@include media-breakpoint-down(sm){
			width: 276px !important;
		}

		&:hover{
			transform: translateY(5px);
		}

		.box__titulo{
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			@include media-breakpoint-down(md){
				flex-direction: column-reverse;
				align-items: center;
			}
		}

		.titulo{
			font-size: 30px;
			color: #000;
			font-family: 'latobold';


			@include media-breakpoint-down (md){
				text-align: center;
				margin-bottom: 15px;
			}
		}

		.subtitulo{
			font-size: 22px;
			color: #000;
			font-family: 'latobold';
			margin-bottom: 20px;

			@include media-breakpoint-down (md){
				text-align: center;
			}
		}

		.data__emissao{
			font-size: 15px;
			color: #000;
			font-family: 'latobold';
			margin-bottom: 10px;

			@include media-breakpoint-down (md){
				text-align: center;
			}
		}

		.descritivo{
			color: #000;
			text-align: justify;
		}

		.emissor{
			font-size: 15px;
			color: #000;
			font-family: 'latobold';
			margin-bottom: 20px;

			@include media-breakpoint-down (md){
				text-align: center;
			}
		}

		.consideracoes__finais{
			color: #000;
			margin-top: 30px;
			text-align: justify;
		}

		.selo{
			border-radius: 50%;
			margin-right: 15px;

			@include media-breakpoint-down (md){
				display: flex;
				justify-content: center;
				margin-right: 0;
			}

			img{
				border-radius: 50%;
			}
		}



		.box__img{
			margin-right: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-breakpoint-down(md){
				margin-right: 0;
				margin-bottom: 20px;
			}
		}
	}


	.border-b{
		border-bottom: 1px solid #000;
		padding-bottom: 10px;

		label{
			cursor: pointer;
		}
	}
}
