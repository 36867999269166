.parceiros__interna{
	padding: 50px 0px;
}
.parceiros__interna{
	.parceiros__card{
		margin-bottom: 20px;
	}
	.parceiros__img{
		margin-bottom: 10px;
	}
}
