.segura__alerta{
	position:fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	z-index:900;

	.alert{
		border-radius:0;
		color:#FFF;
		box-shadow: 0 0 4px rgba(#000,0.8);
		animation:rubberBand 1s linear;
		max-width:700px;
		width: 100%;
		position: relative;
		min-height: 56px;

		&.alert__custom{
			background-color: #2275a8;

			.close{
				font-size: 16px;
				line-height: 16px;
				background-color: #fdb813;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 1;
				margin-left: 5px;
				float: none;
				position: absolute;
				right: 10px;
				top: 10px;

				&:hover{
					background-color: #fdb813;
					color: #000;
				}
			}
		}
		.alerta__mensagem{
			padding-top: 2px;
			text-align: justify;
			padding-right: 46px;
		}

		@include media-breakpoint-down(sm){
			width: 220px;
		}

		h2{
			font-size:18px;
		}
	}
}
