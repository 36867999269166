.argon__interna{
	padding: 40px 0px 50px 0px;
}
.breadcrumbs__internas{
	padding: 15px 0px;
	background-color: #2275a8;
	font-family: 'latosemibold';
	color: #fff;
	font-size: 15px;

	@include media-breakpoint-down (xs){
		font-size: 13px;
	}

	.container{
		display: flex;
		justify-content: flex-end;
		align-items: center;

		span{
			&:first-child{
				margin-right: 10px;
			}
		}
	}
}
