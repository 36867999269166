.whats__btn{
	position: fixed;
	z-index: 998;
	right: 15px;
	bottom: 15px;
	font-size: 40px;
	color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	box-shadow: 0 0 16px rgba(#000, 0.5);
	background-color: #4caf50;

	&:after{
		content: '';
		position: absolute;
		z-index: -1;
		top: calc(50% - 5px);
		left: calc(50% - 5px);
		width: 10px;
		height: 10px;
		background-color: #4caf50;
		border-radius: 50%;
		opacity: 0;
		animation: infiniteRipple 1.5s infinite;
	}
}
.rodape__conteudo{
	padding: 45px 0px;
}
.rodape__creditos{
	z-index: 1;
	background-color: #0e3959;
	color: #fff;
}
.rodape__row{
	align-items: flex-end;
}
.info__item{
	color: #0e3959;
	position: relative;
	font-family: 'latomedium';
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 10px;

	&:after{
		width: 12px;
		height: 12px;
		content: '';
		position: absolute;
		top: 3px;
		left: -20px;
		background-color: #0e3959;
	}
}
.segura__creditos{
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	padding: 10px 0px;
	font-size: 11px;

	span{
		font-style: italic;
	}
}
.rodape__info{
	list-style: none;
	padding-left: 20px;
	margin-bottom: 0px;
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5px;

	&:hover{
		text-decoration: none;
	}

	span{
		font-style: normal;
	}
}
.gv8__logo{
	margin-left: 10px;
}
.segura__rdp__interna{
	box-shadow: 0px 0px 10px rgba(#000, 0.6);
}
@include media-breakpoint-down(md){
	.rodape__logo{
		text-align: center;
		margin-bottom: 30px;
	}
}
@keyframes infiniteRipple {
	0%{
		opacity: 1;
	}
	100%{
		transform: scale(10);
		opacity: 0;
	}
}
