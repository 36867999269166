.suporte__interna{
	padding-top: 30px;
}
.suporte__header{
	padding-top: 76px;
	position: relative;
	margin-bottom: 30px;

	&:after{
		width: 100%;
		height: 250px;
		content: '';
		position: absolute;
		z-index: -1;
		top: 0px;
		left: 0px;
		background-image: url('../images/acesso__letra.png');
		background-position: left top;
		background-repeat: no-repeat;
		background-size: 551px 244px;
	}

	.sub__secao{
		color: #000000;
		margin-bottom: 25px;
	}
}
.suporte__desc{
	margin-bottom: 30px;
}
.suporte__tab{
	margin-bottom: 20px;

	.nav-link{
		display: flex;
		align-items: center;

		&.active{
			.suporte__icon{
				background-color: #fff;
			}
		}
	}
}
.suporte__mini__img{
	margin-right: 15px;
}
.suporte__icon{
	width: 30px;
	height: 30px;
	mask-size: auto;
	mask-position: center center;
	mask-repeat: no-repeat;
	-webkit-mask-size: auto;
	-webkit-mask-position: center center;
	-webkit-mask-repeat: no-repeat;
	background-color: #000;
	z-index: 5;
	transition: all 0.3s linear;
}
.suporte__conteudo{
	.sub__secao{
		color: #000;
		margin-bottom: 25px;
		text-align: center;
	}
}
.suporte__conteudo__desc{
	text-align: center;
	max-width: 890px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 36px
}
.suporte__img{
	margin-bottom: 30px;
}
