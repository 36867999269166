.produtos__interna__header{
	margin-bottom: 40px;
	text-align: center;

	.sub__secao{
		color: #000;
		margin-bottom: 20px;
	}
}
.produtos__interna{
	padding: 30px 0px 100px 0px;

	.produtos__card{
		margin-bottom: 30px;
	}
}
.produto__detalhe__btn{
	padding-top: 30px;
}
.produto__detalhe__titulo{
	text-align: center;
	color: #000;
	margin-bottom: 30px;
}
