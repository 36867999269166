.page__requisitos{
	padding-bottom: 82px;
}
.segura__tabela{
	margin-bottom: 90px;
}
.breadcrumb__custom{
	background-color: #2275a8;
	color: #fff;
	padding: 15px 0px;
	margin-bottom: 50px;

	.container{
		display: flex;
		justify-content: flex-end;
		align-items: center;

		span,
		a{
			display: inline-block;
			margin-left: 7px;
		}
	}
}
.tabela__titulo{
	font-size: 30px;
	line-height: 34px;
	font-family: latomedium;
	margin-bottom: 10px;
	color: #010101;
}
.tabela__desc{
	text-align: justify;
	margin-bottom: 38px;
}
.tabela__estrutura{
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(1, 1fr);
		row-gap: 20px;
	}
}
.tabela__header{
	background-color: #e5e5e5;
	padding: 18px 22px;
	color: #010101;
	font-family: latobold;
	font-size: 20px;
	line-height: 24px;
}
.tabela__texto{
	border-bottom: 1px solid #e5e5e5;
	border-right: 1px solid #e5e5e5;
	border-left: 1px solid #e5e5e5;
	padding: 16px 22px 36px 22px;
	p{
		margin-bottom: 0px;
	}
}
.segura__rodape__requisitos{
	background-image: url(../images/bg__blog.png);
	background-position: right bottom;
	background-size: auto;
	background-repeat: no-repeat;
}
@include media-breakpoint-up (lg) {
	.tabela__mbl{
		display: none;
	}
	.tabela__header__lista,
	.tabela__item__lista{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		align-items: stretch;
	}
	.tabela__dsktop{
		flex-direction: column;
		display: flex;
	}
}
@include media-breakpoint-down (md) {
	.tabela__dsktop{
		display: none;
	}
}
