.blog__interna{
	padding: 30px 0px;
}
.noticia__destaque{
	.blog__card{
		max-width: 1100px;
		padding-bottom: 80px;
	}
	.blog__img{
		text-align: center;
	}
	.blog__card__desc{
		--linhas: 4;
		height: 0px;
	}

	&:hover{
		.blog__card__desc{
			height: 68px;
		}
	}
}
.noticia__interna__header{
	color: #000;
	margin-bottom: 30px;
}
.mais__vistos__header{
	margin-bottom: 30px;

	.sub__secao{
		color: #000;
	}
	.blog__controls{
		font-size: 22px;
		line-height: 22px;
		color: #2275a8;
		display: flex;
		align-items: center;

		.barrinha{
			font-size: 18px;
			line-height: 18px;
		}
	}
}
.paginacao__recentes{
	display: flex;
	justify-content: center;
	font-family: 'latobold';
	font-size: 18px;
	align-items: center;
	color: #000;
}
.paginacao__item{
	padding: 10px;
	position: relative;
	display: flex;
	align-items: center;
	transition: all 0.3s linear;

	&:hover{
		color: #2275a8;
		text-decoration: none;

		&:after{
			color: #000;
		}
	}

	&:last-child{
		&:after{
			display: none;
		}
	}

	&:after{
		width: 2px;
		height: 18px;
		content: '|';
		display: inline-block;
		position: absolute;
		right: 0px;
		top: 48%;
		transform: translateY(-50%);
	}
}
.paginacao__item--active{
	color: #2275a8;

	&:after{
		color: #000;
	}
}
.owl__vistos{
	margin-bottom: 20px;

	.owl-item{
		padding-bottom: 10px;
	}
}
.recentes__header{
	margin-bottom: 30px;

	.sub__secao{
		color: #000;
	}
}
.row__recentes{
	margin-bottom: 40px;
}
.box__categorias,
.box__newsletter,
.box__recentes{
	background-color: #fff;
	margin-bottom: 30px;
	border: 1px solid #ededed;
	padding-bottom: 10px;
}
.categoria__header,
.newsletter__header{
	color: #fff;
	font-family: 'latobold';
	font-size: 18px;
	line-height: 18px;
	background-color: #2275a8;
	padding: 12px;
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}
.categoria__item{
	font-size: 16px;
	font-family: 'latobold';
	color: #000;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 20px;
	transition: all 0.3s linear;

	&:hover{
		color: #2275a8;
		text-decoration: none;

		.categoria__numero{
			color: #fff;
			background-color: #2275a8;
		}
	}
}
.categoria__numero{
	display: inline-block;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 16px;
	transition: all 0.3s linear;
}
.categoria__item--active{
	color: #2275a8;

	.categoria__numero{
		color: #fff;
		background-color: #2275a8;
	}
}
.input__custom{
	height: auto;
	padding: 10px;
	color: #000;
	font-family: 'latobold';
	font-size: 18px;
	line-height: 22px;
	border: none;
	border-bottom: 1px solid #000;
	transition: all 0.3s linear;
	width: 100%;
	display: block;
	resize: none;

	&:focus{
		outline: none;
		box-shadow: none;
		border-bottom: 1px solid #2275a8;
	}

	&::placeholder{
		color: #000;
	}
}
.newsletter__form{
	padding: 10px 20px 0px 20px;
}
.newsletter__desc{
	padding: 10px 20px 0px 20px;
}
.button__form{
	text-align: right;

	.btn__conteudo{
		background-color: transparent;
		border: none;

		&:focus{
			outline: none;
		}
	}
}
.blog__detalhe__interna{
	padding-top: 35px;
}
.noticia__detalhe__titulo{
	font-family: 'latobold';
	font-size: 18px;
	line-height: 22px;
	color: #2275a8;
	margin-bottom: 20px;
}
.blog__data__interna{
	margin-bottom: 10px;
}
.box__recentes{
	padding-bottom: 40px;
}
.recente__item{
	padding: 20px 0px;
	display: block;
	margin: 0px 20px;
	border-bottom: 1px solid #ededed;

	&:last-child{
		border-bottom: none;
	}

	.blog__titulo{
		margin-bottom: 0px;
		color: #000;
	}
	.blog__detalhe{
		margin-bottom: 5px;
	}

	&:hover{
		text-decoration: none;

		.blog__titulo{
			color: #2275a8;
		}
	}
}
.voltar__blog__detalhe{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 5px;
	margin-bottom: 20px;
	border-top: 1px solid #ededed;
}
.blog__sociais{
	font-family: 'latobold';
	font-size: 15px;
	line-height: 15px;
	color: #000;

	.fab{
		font-size: 20px;
	}
}
.link__sociais__blog{
	display: inline-block;
	transition: all 0.3s linear;
	margin-left: 20px;

	&:hover{
		text-decoration: none;
		color: lighten(#000, 50%);
	}
}
@include media-breakpoint-up (lg){
	.row__recentes{
		align-items: flex-end;
	}
	.box__newsletter{
		margin-bottom: 76px;
		box-shadow: 0px 0px 5px rgba(#000, 0.5);
	}
}
@include media-breakpoint-down (md){
	.mais__vistos__header{
		.sub__secao{
			margin-bottom: 10px;
		}
	}
	.recentes__header{
		text-align: center;
	}
	.row__recentes{
		margin-bottom: 0px;
	}
	.paginacao__recentes{
		margin-bottom: 30px;
	}
}
@include media-breakpoint-down (xs){
	.voltar__blog__detalhe{
		flex-direction: column;
		justify-content: center;
	}
}
