.topo{
	z-index: 20;
}
.topo__faixa{
	padding: 5px 0px;
	background-color: #0e3959;
	color: #fff;
	font-family: 'latosemibold';
}
.segura__faixa{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.main__menu{
	font-family: 'latosemibold';
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	color: #000000;
}
.main__icons{
	display: flex;
	align-items: center;
	color: #000;
	font-size: 18px;
	line-height: 18px;

	.barrinha{
		color: #fff;
	}
}
.bg__menu{
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(#000, 0.7);
	width: 100%;
	height: 100%;
	display: none;
}
.topo__pesquisa{
	position: fixed;
	width: 90%;
	max-width: 760px;
	left: 50%;
	transform: translateX(-50%);
	top: -200px;
	transition: all 0.4s linear;
	z-index: 1000;
}
.topo__pesquisa--shown{
	top: 100px;
}
.pesquisa__group{
	width: 100%;
	display: flex;
}
.pesquisa__input{
	display: block;
	width: 100%;
	font-family: 'latomedium';
	font-size: 16px;
	line-height: 20px;
	height: auto;
	padding: 10px 15px;
	color: #888888;

	&::placeholder{
		color: #888888;
	}
}
.pesquisa__submit{
	background-color: #0e3959;
	color: #fff;
	border: none;
	min-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:focus{
		outline: none;
	}
}
.pesquisa__botao{
	transition: all 0.3s linear;

	&:hover{
		color: #fdb813;
	}
}
.clientes__link{
	display: inline-block;
	transition: all 0.3s linear;

	&:hover{
		color: #fdb813;
		text-decoration: none;
	}
}
.faixa__bem__vindo{
	span{
		--linhas: 1;
	}
}
.menu__item--active{
	.menu__link{
		color: #2275a8;
	}
}
.menu__link{
	transition: all 0.3s linear;

	&:hover{
		color: #2275a8;
		text-decoration: none;
	}
}
.segura__area,
.segura__suporte{
	font-size: 14px;
	line-height: 18px;
	a{
		display: flex;
		align-items: center;
		transition: all 0.3s linear;

		&:hover{
			color: #fdb813;
			text-decoration: none;
		}

		span{
			margin: 0px 5px;
		}
	}
}
.segura__area{
	margin-left: 30px;
}
.faixa__suporte{
	display: flex;
	align-items: center;
}
@include media-breakpoint-up (xl){
	.topo{
		position: sticky;
		top: -41px;

		&.topo__interna{
			top: -41px;

			.topo__main{
				background-color: rgba(#fff, 0.7);
			}
		}
	}
	.topo__main{
		padding: 10px 0px;
		background-color: rgba(#fff, 0.7);
		border-bottom: 2px solid rgba(#dbdbdb, 0.5);
	}
	.segura__main{
		display: flex;
		justify-content: space-between;
	}
	.main__menu{
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		padding-top: 20px;
	}
	.menu__item{
		display: flex;
	}
	.menu__link{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.main__logo{
		display: block;
		margin-right: 45px;
	}
	.main__icons{
		margin-left: 35px;
		padding-top: 20px;
	}
	.pesquisa__botao{
		background-color: transparent;
		border: none;
		padding: 0px;

		&:focus{
			outline: none;
		}
	}
	.topo__mbl{
		display: none;
	}
}
@include media-breakpoint-down (lg){
	.topo__main{
		position: fixed;
		z-index: 1000;
		width: 250px;
		transition: all 0.3s linear;
		height: 100%;
		overflow-y: auto;
		background-color: #fff;
		top: 0;
		left: -250px;
	}
	.topo__main--shown{
		left: 0px;
	}
	.segura__mbl{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mbl__botao,
	.pesquisa__botao__mbl{
		background-color: transparent;
		border: none;
		padding: 0px;
		font-size: 24px;
		line-height: 24px;

		&:focus{
			outline: none;
		}
	}
	.topo__mbl{
		padding: 10px 0px;
		border-bottom: 2px solid rgba(#dbdbdb, 0.5);
		background-color: #fff;
	}
	.faixa__bem__vindo{
		max-width: 80%;
	}
	.topo{
		position: sticky;
		top: -41px;

		&.topo__interna{
			top: 0px;
		}
	}
	.pesquisa__botao{
		display: none;
	}
	.main__logo{
		display: block;
		padding: 5px;
		margin-bottom: 20px;
	}
	.segura__main{
		padding: 0px;
	}
	.menu__link{
		display: block;
		padding: 10px;
		background-color: #fff;
		transition: all 0.3s linear;

		&:hover{
			color: #fff;
			background-color: #2275a8;
		}
	}
	.menu__item--active{
		.menu__link{
			color: #fff;
			background-color: #2275a8;
		}
	}
	.main__menu{
		margin-bottom: 20px;
	}
	.main__icons{
		display: flex;
		justify-content: center;
		font-size: 24px;
		color: #000;
	}
}
@include media-breakpoint-down (md) {
	.icon__txt{
		display: none;
	}
	.segura__area{
		margin-left: 10px;
	}
}
@include media-breakpoint-down (sm){
	.mbl__logo{
		max-width: 160px;
	}
}
@include media-breakpoint-down (xs){
	.mbl__logo{
		max-width: 130px;
	}
}
