#produtos-mod-2{
	color: #777;

	padding-top: 60px;

	.subtitle-produtos{
		font-weight: bold;
		font-size: 24px;
		color: #000;
		display: flex;
		align-items: flex-start;
		margin-bottom: 10px;

		svg{
			color: #0e3959;
			margin-right: 5px;
			vertical-align: middle;
		}
	}

	.box__btns{
		display: flex;
		align-items: center;

		.btn__conteudo{
			margin-right: 30px;
		}
	}

	.btn__fale{
		width: 130px;
		margin-bottom: 10px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #0e3959 !important;;
		color: #fdb813 !important;
		font-family: 'latobold',sans-serif;
		font-size: 14px;
		transition: all .4s linear;

		&:hover{
			background: #fdb813 !important;
			color: #0e3959 !important;
		}
	}



	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		background-color: #fff;
		box-shadow: 0px 0px 5px rgba(#000, 0.5);

		.foto{
			margin-bottom: 10px;
		}

		.nome{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			padding:10px;
			color: #0e3959;
		}

		.descricao{
			// flex-grow: 1;
			color: #777;
			padding-bottom: 5px;
			padding: 10px;
			text-align: justify;
		}

		.btn{
			$bg: #0e3959;

			border-radius: 0;
			background: $bg;
			color: #FFF;

			&:hover{
				background: darken($bg, 10%);
				color:#FFF;
			}
		}


	}

	.paginacao{
		display: flex;
		justify-content:center;
		color: #0e3959;

		& > *{
			margin:0;
			padding:0.3em 0.9em;

			&:first-child{
				border-radius:2em 0 0 2em;
				border-right-width:0;

				& ~ *{
					border-left:none;
					border-right-width:0;
				}
			}

			&:last-child{
				border-radius:0 2em 2em 0;
				border-right-width:2px !important;
			}

			&:hover{
				color: #0e3959;
			}
		}

		.active{
			background:#0e3959;
			border-color:#0e3959;
			color:#FFF;
		}
	}

	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: #0e3959;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #FFF;

				&:hover{
					color: #fff;
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}
}
